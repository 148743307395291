import React from 'react';

// External Components

import { Box } from '@thepuzzlers/pieces';

export const SpringLine = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      className="spring-line"
      viewBox="0 0 175 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'red',
        ...sx
      }}
      {...props}>
      <path
        d="M1 38.1097C10.4739 34.0339 21.6804 34.1441 28.7213 39.7987C31.5598 42.0752 32.8868 45.8205 35.6147 49.3454C38.6375 53.2375 43.2086 53.4211 46.4895 53.0907C62.5619 51.6219 69.6397 19.2365 63.6678 16.2623C60.1289 14.4999 54.968 18.5022 51.0974 30.2152C46.084 45.233 51.0237 54.7797 54.7469 58.6351C64.4788 68.6591 87.1498 45.0494 90.7255 21.0357C91.684 14.61 93.7483 6.38517 88.9192 2.30945C83.2054 -2.46391 77.8602 6.71563 76.6437 15.3077C74.3582 31.5004 80.9199 58.3414 91.0942 60.1406C101.232 61.903 111.332 33.1527 110.337 17.4373C109.968 11.7093 108.162 6.4586 104.328 6.89922C100.716 7.30312 98.8355 13.7655 98.0614 20.5951C96.4025 35.6495 101.527 58.9288 114.318 56.7992C129.543 54.2656 143.772 8.36793 129.174 9.57963C119.59 10.4241 121.691 53.3844 131.791 56.0648C141.081 58.5249 148.454 45.9673 151.771 34.1074C155.31 21.4029 148.196 15.8585 144.804 23.9732C135.404 46.4814 158.997 58.2312 174 38.2565"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
    </Box>
  );
};
