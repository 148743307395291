import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisManagementAndInvestorsPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisManagementAndInvestorsPage {
        nodes {
          entry {
            header {
              headline
              title
            }
            about {
              description {
                html
              }
            }
            investor_list {
              list {
                name {
                  html
                }
                position
                description {
                  html
                }
                photo {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
