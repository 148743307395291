import React from 'react';

import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';
// External Components
import { Box, Link } from '@thepuzzlers/pieces';

export const PrimaryButton = ({
  text,
  to,
  sx,
  variant = 'buttons.primary',
  isExternalLink,
  textSx,
  isSecondAnimationVariant,
  className,
  ...props
}) => {
  return (
    <Box
      className={`__additional-scrub-animation-box ${className}`}
      sx={{
        width: 'max-content',
        ...sx
      }}
      {...props}>
      {/* Additional box required for second animation*/}
      <Box
        className="__additional-hover-animation-box"
        variants={buttonHoverAnimation}
        custom={{
          initialRotation: isSecondAnimationVariant ? 2 : -2
        }}
        initial="initial"
        whileHover="animate"
        sx={{
          width: 'max-content',
          transform: isSecondAnimationVariant
            ? 'rotate(2deg)'
            : 'rotate(-2deg)',
          outline: '1px solid transparent'
        }}>
        {isExternalLink ? (
          <Link
            href={to}
            variant={variant}
            sx={{
              width: 'max-content',
              display: 'inline-block',
              ...textSx
            }}>
            {text}
          </Link>
        ) : (
          <NavigationLink
            sx={{
              display: 'flex',
              width: 'max-content',
              '& a': {
                variant,
                ...textSx
              }
            }}
            to={to}>
            {text}
          </NavigationLink>
        )}
      </Box>
    </Box>
  );
};

// Animation
const buttonHoverAnimation = {
  initial: ({ initialRotation }) => ({
    rotate: initialRotation
  }),
  animate: ({ initialRotation }) => ({
    rotate: initialRotation * -1,
    transition: {
      repeatType: 'reverse',
      repeat: Infinity,
      duration: 0.5
    }
  })
};
