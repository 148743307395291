import React from 'react';

// External components
import { Section, Heading, Paragraph, Link, Box } from '@thepuzzlers/pieces';

// Data
import { GridSpacer, HorizontalDivider, PrimaryButton } from 'components';
import { useNavigationData } from 'sections/Navigation/useNavigationData';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Assets
import { NoahLogo } from 'components';
import { useKisBusinessInfoData, useKisFooterData } from 'graphqlHooks';
import { convertPtoSpan } from 'graphqlHooks/helper';
import { useSolutionLinks } from 'sections/Navigation';

export const Footer = () => {
  const {
    legalLinks,
    solutionLinkTitle,
    noahGroupLinks,
    noahExperienceLinks,
    thePuzzlersLink
  } = useNavigationData();
  const { address, email } = useKisBusinessInfoData();
  const { headline } = useKisFooterData();
  return (
    // Markup
    <Section as="footer">
      <Headline headline={headline} />
      <MailButton email={email} />
      <LinkSpacer />
      <SolutionLinks solutionLinkTitle={solutionLinkTitle} />
      <NoahGroupLinks noahGroupLinks={noahGroupLinks} />
      <NoahExperienceLinks noahExperienceLinks={noahExperienceLinks} />
      <AddressSpacer />
      <Logo />
      <Address address={address} />
      <DividerLine />
      <LegalAndPrivacy legalLinks={legalLinks} />
      <CopyRight link={thePuzzlersLink} />
      <BottomSpacer />
    </Section>
  );
};

// Elements

const Headline = ({ headline }) => {
  return (
    <Heading
      as="h2"
      className="__headline"
      dangerouslySetInnerHTML={{ __html: convertPtoSpan(headline.html) }}
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1.25,
        fontSize: ['3.2rem', null, '4.8rem', null, '4rem', '4.8rem'],
        '& strong': {
          fontFamily: 'primary.bold'
        },
        gridColumn: [
          '1/13',
          null,
          '2/ span 22',
          null,
          '1/ span 12',
          '2/ span 12'
        ],
        '& > span': {
          display: 'block',
          '&:nth-of-type(2)': {
            textIndent: ['5.5rem', null, '8.6rem', null, '6.5rem', '8.6rem']
          }
        }
      }}
    />
  );
};

const MailButton = ({ email }) => {
  return (
    <PrimaryButton
      isExternalLink
      text={email}
      to={`mailto:${email}`}
      sx={{
        gridColumn: ['1/13', null, '1/25', null, '16/ 25', '17/25'],
        mt: ['5rem', null, '5rem', null, 0, 0],
        '& a': {
          fontSize: ['2.8rem', null, '2.8rem', null, '2.8rem', '2.8rem']
        },
        justifySelf: ['center', null, 'center', null, 'start', 'start'],
        alignSelf: [null, null, null, null, 'center', 'center']
      }}
    />
  );
};

const SolutionLinks = ({ solutionLinkTitle }) => {
  const solutionLinks = useSolutionLinks();
  return (
    <PageLinksGroup
      title={solutionLinkTitle}
      links={solutionLinks}
      sx={{
        gridColumn: ['1/13', null, '1/ span 8', null, '1/ span 6', '2/ span 5'],
        mt: ['18rem', null, 0, null, 0, 0]
      }}
    />
  );
};
const NoahGroupLinks = ({ noahGroupLinks: { title, links } }) => {
  return (
    <PageLinksGroup
      title={title}
      links={links}
      sx={{
        gridColumn: [
          '1/13',
          null,
          '10/ span 8',
          null,
          '8/ span 6',
          '8/ span 5'
        ],
        mt: ['5.4rem', null, 0, null, 0, 0]
      }}
    />
  );
};
const NoahExperienceLinks = ({ noahExperienceLinks: { title, links } }) => {
  return (
    <PageLinksGroup
      title={title}
      links={links}
      sx={{
        gridColumn: ['1/13', null, '19/25', null, '15/ span 6', '14/ span 5'],
        mt: ['5.4rem', null, 0, null, 0, 0]
      }}
    />
  );
};

const PageLinksGroup = ({ title, links, sx }) => {
  return (
    <Box
      className="__page-links-group"
      sx={{
        ...sx
      }}>
      <Paragraph
        sx={{
          fontFamily: 'body.normal',
          letterSpacing: '0.02em',
          lineHeight: 1,
          fontSize: ['1.6rem', null, '1.6rem', null, '1.4rem', '1.4rem']
        }}>
        {title}
      </Paragraph>
      {links.map((link, index) => (
        <PageLinkItem key={index} data={link} />
      ))}
    </Box>
  );
};

const PageLinkItem = ({ data: { text, to } }) => {
  return (
    <NavigationLink
      to={to}
      sx={{
        mt: ['2rem', null, '1.6rem', null, '1.8rem', '1.8rem'],
        display: 'block',
        '& a': {
          fontFamily: 'body.boldItalic',
          lineHeight: 1,
          fontSize: ['1.6rem', null, '1.6rem', null, '1.4rem', '1.4rem'],
          letterSpacing: '0.02em'
        }
      }}>
      {text}
    </NavigationLink>
  );
};

const Logo = () => {
  return (
    <NoahLogo
      sx={{
        width: ['15rem', null, '14.8rem', null, '15.2rem', '14.6rem'],
        gridColumn: ['1/ span 7', null, '16/25', null, '20/25', '19/ span 5'],
        alignSelf: 'center',
        justifySelf: [null, null, 'end', null, 'end', 'end'],
        mt: ['10rem', null, 0, null, 0, 0]
      }}
    />
  );
};

const Address = ({ address }) => {
  return (
    <Paragraph
      className="__address"
      sx={{
        fontFamily: 'body.normal',
        alignSelf: 'center',
        lieHeight: 1.75,
        fontSize: ['1.6rem', null, '1.6rem', null, '1.4rem', '1.4rem'],
        gridColumn: [
          '1/13',
          null,
          '1/ span 10',
          null,
          '1/ span 14',
          '2/ span 12'
        ],
        gridRow: [null, null, 6, null, 5, 5],
        mt: ['1.7rem', null, 0, null, 0, 0],
        letterSpacing: '0.02em'
      }}>
      {address}
    </Paragraph>
  );
};

const DividerLine = () => {
  return (
    <HorizontalDivider
      sx={{
        gridColumn: ['1/13', null, '1/25', null, '1/25', '2/ span 22'],
        mt: ['4rem', null, '3.4rem', null, '2.3rem', '1rem'],
        mb: [null, null, '3.4rem', null, '3rem', '3.9rem']
      }}
    />
  );
};

const LegalAndPrivacy = ({ legalLinks }) => {
  return (
    <Box
      sx={{
        gridColumn: [
          '1/ span 7',
          null,
          '1/ span 7',
          null,
          '1/ span 7',
          '2/ span 7'
        ],

        mt: ['4rem', null, 0, null, 0, 0],
        alignSelf: [null, null, 'center', null, 'center', 'center']
      }}>
      {/* the empty space is required */}
      <LegalLinkItem link={legalLinks[0]} />
      <LegalLinkItem link={legalLinks[1]} />
    </Box>
  );
};

const LegalLinkItem = ({ link: { text, to } }) => {
  return (
    <NavigationLink
      to={to}
      sx={{
        fontFamily: 'body.boldItalic',
        lineHeight: 1,
        fontSize: ['1.6rem', null, '1.6rem', null, '1.4rem', '1.4rem'],
        letterSpacing: '0.02em',
        ':not(:first-of-type)': {
          ml: ['5.5rem', null, '5.7rem', null, '7.6rem', '8.2rem']
        }
      }}>
      {text}
    </NavigationLink>
  );
};

const CopyRight = ({ link: { text, to } }) => {
  return (
    <Link
      href={to}
      target="_blank"
      sx={{
        gridColumn: ['1/13', null, '13/25', null, '13/25', '13/ span 11'],
        mt: ['1.9rem', null, 0, null, 0, 0],
        justifySelf: [null, null, 'end', null, 'end', 'end'],
        fontFamily: 'body.boldItalic',
        lineHeight: 1,
        letterSpacing: '0.02em',
        fontSize: ['1.6rem', null, '1.6rem', null, '1.4rem', '1.4rem']
      }}>
      {text}
    </Link>
  );
};

const LinkSpacer = () => {
  return (
    <GridSpacer
      className="__link-spacer"
      height={[null, null, '15rem', null, '10rem', '10rem']}
      sx={{
        display: ['none', null, 'block', null, 'block', 'block']
      }}
    />
  );
};
const AddressSpacer = () => {
  return (
    <GridSpacer
      className="__address-spacer"
      height={[null, null, '8rem', null, '10rem', null, '6rem']}
      sx={{
        display: ['none', null, 'block', null, 'block', 'block']
      }}
    />
  );
};

const BottomSpacer = () => {
  return <GridSpacer height="5rem" />;
};
