import React from 'react';

// External Components

import { Box } from '@thepuzzlers/pieces';

export const Building = ({ sx, ...props }) => {
  return (
    <Box
      className="building"
      as="svg"
      viewBox="0 0 83 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M51.7568 58.3059L59.1527 46.5695L79.1461 26.5762"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M69.2764 36.4113V21.5977"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M62.291 25.2476L69.276 30.2306"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M81.5543 32.0884H73.6602"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M59.1396 46.5607V38.6665"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M79.1454 40.3892L65.9316 39.939"
        stroke="#1D1D1B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M48.8682 64.3904L51.8012 25.103L55.097 64.3904H48.8682Z"
        fill="#D6D5D0"
      />
      <path
        d="M45.6992 22.708L48.8683 64.3907L51.8013 25.1034L45.6992 22.708Z"
        fill="#9D9D99"
      />
      <path
        d="M41.7295 64.3339L45.6984 22.708L48.8675 64.3907L41.7295 64.3339Z"
        fill="#D8D7D1"
      />
      <path
        d="M39.4043 20.2383L45.6987 22.7079L41.7297 64.3338L39.4043 64.3906V20.2383Z"
        fill="#A9A8A5"
      />
      <path
        d="M12.7232 64.3903L10.848 52.4048L9.9082 64.3903H12.7232Z"
        fill="#9E9F9A"
      />
      <path
        d="M18.3798 51.4653L20.631 64.3906H16.5527L18.3798 51.4653Z"
        fill="#D8D7D2"
      />
      <path
        d="M30.9986 64.3905L30.1025 42.3121L34.7097 0L39.4042 39.9517V64.3905H30.9986Z"
        fill="#A2A29E"
      />
      <path
        d="M30.1024 0L27.8906 18.8744L30.1024 42.3121L34.7095 0H30.1024Z"
        fill="#B6B6B3"
      />
      <path
        d="M24.1182 64.3902L27.8904 18.874L30.1022 42.3118L30.9983 64.3902H24.1182Z"
        fill="#D3D2CD"
      />
      <path d="M27.2656 0L27.8907 18.8744L30.1025 0H27.2656Z" fill="#D5CDC7" />
      <path
        d="M23.4023 0H27.2663L27.8914 18.8744L24.1191 64.3905H20.631L18.3799 51.4652L23.4023 0Z"
        fill="#9C9C96"
      />
      <path
        d="M10.8477 52.4051L18.379 51.4653L16.5519 64.3906H12.7229L10.8477 52.4051Z"
        fill="#B7B6B2"
      />
      <path d="M34.71 0L39.4045 39.9518V0H34.71Z" fill="#D3D3CD" />
      <path
        d="M34.291 89.9789C36.5465 89.096 37.6874 87.6011 41.547 87.6011C45.4067 87.6011 46.5475 89.1004 48.8074 89.9789"
        stroke="#3D55A3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.883 83.501C64.4459 83.4486 63.9695 83.4224 63.4406 83.4224C57.968 83.4224 57.968 86.4297 52.4954 86.4297C47.0228 86.4297 47.0228 83.4224 41.5502 83.4224C36.0776 83.4224 36.0776 86.4297 30.605 86.4297C25.1324 86.4297 25.1324 83.4224 19.6598 83.4224C19.1309 83.4224 18.6544 83.453 18.2217 83.501"
        stroke="#3D55A3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.54785 63.9008C4.18701 64.7095 5.45463 65.5312 8.71546 65.5312C14.1881 65.5312 14.1881 62.5239 19.6607 62.5239C25.1333 62.5239 25.1333 65.5312 30.6059 65.5312C36.0785 65.5312 36.0785 62.5239 41.5511 62.5239C47.0237 62.5239 47.0237 65.5312 52.4963 65.5312C57.9689 65.5312 57.9689 62.5239 63.4415 62.5239C68.9141 62.5239 68.9141 65.5312 74.3867 65.5312C77.6475 65.5312 78.9151 64.7095 80.5587 63.9008"
        stroke="#3D55A3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M82.1012 59.0577C79.4741 60.0019 78.5737 61.3525 74.3862 61.3525C68.9136 61.3525 68.9136 58.3452 63.441 58.3452C57.9684 58.3452 57.9684 61.3525 52.4958 61.3525C47.0232 61.3525 47.0232 58.3452 41.5506 58.3452C36.078 58.3452 36.078 61.3525 30.6054 61.3525C25.1328 61.3525 25.1328 58.3452 19.6602 58.3452C14.1876 58.3452 14.1876 61.3525 8.71497 61.3525C4.52747 61.3525 3.62702 59.9975 1 59.0577"
        stroke="#3D55A3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M68.6778 80.6157C67.3839 79.9076 66.0202 79.2432 63.4412 79.2432C57.9686 79.2432 57.9686 82.2505 52.496 82.2505C47.0234 82.2505 47.0234 79.2432 41.5508 79.2432C36.0782 79.2432 36.0782 82.2505 30.6056 82.2505C25.133 82.2505 25.133 79.2432 19.6604 79.2432C17.0814 79.2432 15.7177 79.9119 14.4238 80.6157"
        stroke="#3D55A3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.04785 69.2073C5.97452 69.5089 7.09789 69.7099 8.71519 69.7099C14.1878 69.7099 14.1878 66.7026 19.6604 66.7026C25.133 66.7026 25.133 69.7099 30.6056 69.7099C36.0782 69.7099 36.0782 66.7026 41.5508 66.7026C47.0234 66.7026 47.0234 69.7099 52.496 69.7099C57.9686 69.7099 57.9686 66.7026 63.4412 66.7026C68.9138 66.7026 68.9138 69.7099 74.3864 69.7099C76.0037 69.7099 77.1315 69.5089 78.0538 69.2029"
        stroke="#3D55A3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M71.7073 77.7658C68.7044 77.0008 67.8345 75.0645 63.4416 75.0645C57.969 75.0645 57.969 78.0718 52.4964 78.0718C47.0238 78.0718 47.0238 75.0645 41.5512 75.0645C36.0786 75.0645 36.0786 78.0718 30.606 78.0718C25.1334 78.0718 25.1334 75.0645 19.6608 75.0645C15.2678 75.0645 14.398 77.0008 11.3994 77.7658"
        stroke="#3D55A3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.08984 73.8804C8.29091 73.8892 8.49636 73.8935 8.71491 73.8935C14.1875 73.8935 14.1875 70.8862 19.6601 70.8862C25.1327 70.8862 25.1327 73.8935 30.6053 73.8935C36.0779 73.8935 36.0779 70.8862 41.5505 70.8862C47.0231 70.8862 47.0231 73.8935 52.4957 73.8935C57.9683 73.8935 57.9683 70.8862 63.4409 70.8862C68.9135 70.8862 68.9135 73.8935 74.3861 73.8935C74.6047 73.8935 74.8101 73.8892 75.0112 73.8804"
        stroke="#3D55A3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Box>
  );
};
