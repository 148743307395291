import { merge } from 'theme-ui';
import { BaseTheme } from 'gatsby-theme-thepuzzlers-core';
import { theme as piecesTheme } from '@thepuzzlers/pieces';
// client imports
import { fontFamilies } from './fonts/fontFamilies';
import { colors } from './colors';
import { buttons, links, cards } from './elements';
import { shadows, radii } from './styles';
import { forms } from './forms';

const breakpoints = [
  '@media screen and (min-width: 10000px) and (orientation: portrait)', // not triggered phone XL
  '@media screen and (min-width: 750px)',
  '@media screen and (min-width: 12000px) and (orientation: portrait)', // not triggered phone Landscape
  '@media screen and (min-width: 1000px)',
  '@media screen and (min-width: 1400px)'
];

const theme = merge(BaseTheme, {
  // pieces styles
  breakpoints: breakpoints,
  measure: piecesTheme.measure,
  grids: piecesTheme.grids,
  // local styles
  fonts: fontFamilies,
  colors,
  buttons,
  links,
  cards,
  shadows,
  radii,
  forms,
  styles: {
    root: {
      fontFamily: 'body.normal',
      color: 'text',
      bg: 'background',
      '& hr': {
        border: 'none'
      }
    }
  }
});

export default theme;
