import React from 'react';

export const useDimension = (elementRef) => {
  const [dimensions, setDimension] = React.useState({ height: 0, width: 0 });
  // const dimensions = React.useRef({ width: 0, height: 0 });

  React.useLayoutEffect(() => {
    const handleResize = (entries) => {
      const entry = entries[0];
      setDimension({
        height: entry.target.offsetHeight,
        width: entry.target.offsetWidth
      });
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(elementRef.current);
  }, []);

  return dimensions;
};
