import React from 'react';

// External Component
import { Box } from '@thepuzzlers/pieces';

export const HorizontalDivider = ({ sx, ...props }) => {
  return (
    <Box
      as="hr"
      sx={{
        borderTop: '1px solid',
        borderColor: 'primary',
        ...sx
      }}
      {...props}
    />
  );
};
