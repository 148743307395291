import React from 'react';

// External Components

import { Box } from '@thepuzzlers/pieces';

export const OpenQuote = ({ sx, ...props }) => {
  return (
    <Box
      className="open-quote"
      as="svg"
      viewBox="0 0 108 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'orange300',
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M48.0379 8.74524C42.5867 12.0101 37.9306 15.0418 34.0694 17.8403C30.4353 20.6388 27.0284 24.1369 23.8486 28.3346V48.2737C30.8896 48.2737 36.5678 50.256 40.8833 54.2205C45.1987 57.9518 47.3565 63.0824 47.3565 69.6122C47.3565 76.1419 45.0852 81.5057 40.5426 85.7034C36.2271 89.9011 30.776 92 24.1893 92C16.694 92 10.7886 89.5513 6.47319 84.654C2.15773 79.7566 0 73.1102 0 64.7148V64.365C0.227129 49.4398 4.31546 36.6134 12.265 25.8859C20.2145 14.9252 30.5489 6.29657 43.2681 0L48.0379 8.74524ZM84.1514 48.2737C90.9653 48.2737 96.53 50.256 100.845 54.2205C105.161 57.9518 107.319 63.0824 107.319 69.6122C107.319 76.1419 105.047 81.5057 100.505 85.7034C96.1893 89.9011 90.7382 92 84.1514 92C76.6561 92 70.7508 89.5513 66.4353 84.654C62.1199 79.7566 59.9621 73.1102 59.9621 64.7148V64.365C60.1893 49.4398 64.2776 36.6134 72.2271 25.8859C80.1766 14.9252 90.511 6.29657 103.23 0L108 8.74524C102.322 12.0101 97.552 15.1584 93.6908 18.1901C90.0568 20.9886 86.7634 24.3701 83.8107 28.3346V48.2737H84.1514Z"
        fill="currentColor"
      />
    </Box>
  );
};
