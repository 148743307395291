import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisBusinessInfoData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisBusinessInfo {
        nodes {
          entry {
            address
            email
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
