import React from 'react';

// External components
import { GridWrapper, Button, Box, Paragraph } from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';
import { useLocation } from '@reach/router';

// Local Components
import {
  HorizontalDivider,
  NoahLogoSmallText,
  NoahLogoWithoutText
} from 'components';

// Data
import { useNavigationData } from '../useNavigationData';
import { useSolutionLinks } from '../useSolutionLinks';

// helper
import { usePathMatch } from '../helper';
import { useDimension } from './useDimension';
import { navMenuContainer } from './animation';

// Animation

// Helper function

export const NavigationBar = () => {
  // use hide animation for navigation bar except in it is used in overlay

  return (
    // Markup
    <ContentWrapper>
      <Logo />
      {/* this additional box needed to fix justify self element end issue on safari*/}
      <Box
        sx={{
          position: 'relative',
          gridColumnEnd: [13, null, 25, null, 25, 25],
          width: 'max-content',
          justifySelf: 'end',
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
        <Menu />
      </Box>
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children, sx }) => (
  <GridWrapper
    className="navigation-bar-wrapper"
    sx={{
      py: ['1.7rem', null, '3.7rem', null, '2.9rem', '2.6rem'],
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 2,
      ...sx
    }}>
    {children}
  </GridWrapper>
);

const Logo = () => (
  <NavigationLink
    to="/"
    sx={{
      alignSelf: 'center',
      gridColumnStart: 1,
      gridRow: 1
    }}>
    <NoahLogoWithoutText
      className="company-logo"
      sx={{
        width: ['3.4rem', null, null, null, null, null],
        display: ['block', null, 'none', null, 'none', 'none', 'none']
      }}
    />
    <NoahLogoSmallText
      className="company-logo"
      sx={{
        width: [null, null, '10.5rem', null, '10.5rem', '10.5rem'],
        display: ['none', null, 'block', null, 'block', 'block']
      }}
    />
  </NavigationLink>
);

const Menu = () => {
  const {
    closeText,
    openText,
    noahGroupLinks,
    noahExperienceLinks,
    solutionLinkTitle,
    homeLink
  } = useNavigationData();

  const containerRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const menuContentRef = React.useRef(null);
  const [showMenu, setShowMenu] = React.useState(false);
  const originalBodyOverflow = React.useRef(null);

  const { width, height } = useDimension(buttonRef);
  const { width: contentWidth, height: contentHeight } =
    useDimension(menuContentRef);
  const handleCloseMenu = () => {
    setShowMenu(false);
    // for small screen that have navigation scroll, should be back to top  so the menu button showing.
    containerRef.current.scrollTop = 0;
  };

  React.useLayoutEffect(() => {
    // add initial height and width for the nav menu
    if (height === 0 && width === 0) {
      containerRef.current.style.height = buttonRef.current.offsetHeight;
      containerRef.current.style.width = buttonRef.current.offsetWidth;
    }
  }, []);

  React.useLayoutEffect(() => {
    originalBodyOverflow.current === document.body.style.overflow;
    // we need to calculate the scrollbar of the browser and implement it, other wise the menu overlay will moved doe to scrollbar lost because of overflow hidden of body
    if (showMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = originalBodyOverflow.current;
    }
  }, [showMenu]);

  // we have to handle this because the overflow hidden remove the scrollbar and affect the menu overlay position
  const handleButtonClick = () => {
    showMenu ? setShowMenu(false) : setShowMenu(true);
  };

  return (
    <Box
      ref={containerRef}
      initial={false}
      custom={{
        height,
        width,
        contentWidth,
        contentHeight
      }}
      animate={width ? (showMenu ? 'open' : 'close') : ''}
      variants={navMenuContainer}
      onMouseEnter={() => {
        !showMenu && setShowMenu(true);
      }}
      onMouseLeave={() => {
        showMenu && handleCloseMenu();
      }}
      sx={{
        bg: 'darkGray',
        position: 'fixed',
        top: ['1.6rem', null, '3.1rem', null, '2.3rem', '1.8rem'],
        maxHeight: ['90vh', null, 'unset', null, 'unset', 'unset'],
        // animation value
        overflow: showMenu
          ? ['hidden auto', null, 'hidden', null, 'hidden', 'hidden']
          : 'hidden',
        zIndex: 10,

        // this fix the pixel issue on rotated element
        outline: '1px solid transparent',
        transform: 'rotate(1deg)',
        transformOrigin: 'right top'
      }}>
      <MenuButton
        ref={buttonRef}
        handleButtonClick={handleButtonClick}
        closeText={closeText}
        openText={openText}
        isMenuShown={showMenu}
      />
      <MenuContent
        ref={menuContentRef}
        homeLink={homeLink}
        noahGroupLinks={noahGroupLinks}
        noahExperienceLinks={noahExperienceLinks}
        solutionLinkTitle={solutionLinkTitle}
        handleClose={handleCloseMenu}
      />
    </Box>
  );
};

const MenuButton = React.forwardRef(
  ({ handleButtonClick, closeText, openText, isMenuShown }, ref) => {
    return (
      <Button
        ref={ref}
        onClick={handleButtonClick}
        variant="clear"
        sx={{
          color: 'white',
          fontFamily: 'primary.bold',
          fontSize: ['1.8rem', null, '2.2rem', null, '2.2rem', '2.4rem'],
          p: [
            '0.7rem 1.6rem 0.7rem 1.6rem',
            null,
            '0.9rem 1.8rem 0.9rem 1.8rem',
            null,
            '0.8rem 1.8rem 0.8rem 1.8rem',
            '0.9rem 2rem 0.9rem 2rem'
          ],
          display: 'flex',
          alignItems: 'center',
          ml: 'auto'
        }}>
        <ButtonLinesWrapper isMenuShown={isMenuShown} />
        <Box
          as="span"
          sx={{
            ml: ['1.2rem', null, '1.2rem', null, '1.2rem', '1.6rem']
          }}>
          {isMenuShown ? closeText : openText}
        </Box>
      </Button>
    );
  }
);

const ButtonLinesWrapper = ({ isMenuShown }) => {
  return (
    <Box>
      <ButtonLine />
      {!isMenuShown && (
        <ButtonLine
          sx={{
            mt: ['0.4rem', null, '0.4rem', null, '0.4rem', '0.5rem']
          }}
        />
      )}
    </Box>
  );
};

const ButtonLine = ({ sx }) => {
  return (
    <HorizontalDivider
      sx={{
        borderTop: '2px solid',
        borderColor: 'white',
        width: ['2.6rem', null, '2.6rem', null, '2.6rem', '3.5rem'],
        ...sx
      }}
    />
  );
};

const MenuContent = React.forwardRef(
  (
    {
      noahGroupLinks,
      noahExperienceLinks,
      solutionLinkTitle,
      handleClose,
      homeLink
    },
    ref
  ) => {
    const solutionLinks = useSolutionLinks();

    return (
      <Box
        ref={ref}
        sx={{
          p: [
            '0.92rem 3.2rem 4.8rem 2.8rem',
            null,
            '1.7rem 4.6rem 5.1rem 4.6rem',
            null,
            '1.7rem 4.6rem 5.1rem 4.6rem',
            null,
            '1.7rem 4.1rem 6.3rem 4.1rem'
          ],
          // Animation Value
          width: ['32rem', null, '41.2rem', null, '41.2rem', '41.4rem'],
          position: 'absolute'
        }}>
        <NavigationLinkItem data={homeLink} handleClose={handleClose} />
        <LinksGroup
          data={{ title: solutionLinkTitle, links: solutionLinks }}
          handleClose={handleClose}
        />
        <LinksGroup data={noahGroupLinks} handleClose={handleClose} />
        <LinksGroup data={noahExperienceLinks} handleClose={handleClose} />
      </Box>
    );
  }
);

const LinksGroup = ({ data: { title, links }, handleClose }) => {
  return (
    <Box
      className="__navigation-link-group"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: ['3.6rem', null, '3.5rem', null, '3.5rem', '4.2rem']
      }}>
      <Paragraph
        className="__links-group-title"
        sx={{
          color: 'white',
          opacity: 0.7,
          fontFamily: 'body.normal',
          letterSpacing: '0.05em',
          textTransform: 'uppercase',
          fontSize: ['1.2rem', null, '1.2rem', null, '1.2rem', '1.2rem']
        }}>
        {title}
      </Paragraph>
      {links.map((link, index) => (
        <NavigationLinkItem data={link} key={index} handleClose={handleClose} />
      ))}
    </Box>
  );
};

const NavigationLinkItem = ({ data: { text, to }, handleClose }) => {
  const { pathname } = useLocation();

  // set the path ref
  const pathRef = React.useRef(null);

  React.useEffect(() => {
    // set the initial path
    if (!pathRef.current) {
      pathRef.current = pathname;
    }
    // change path if current path is not same with path provided by roter
    if (pathRef.current !== pathname) {
      pathRef.current = pathname;
      handleClose();
    }
  }, [pathname]);

  const { isPathMatch } = usePathMatch(to);

  return (
    <NavigationLink
      to={to}
      sx={{
        mt: ['1.7rem', null, '1.7rem', null, '1.7rem', '1.7rem'],
        '& ~ span': {
          mt: ['1.7rem', null, '1.7rem', null, '1.7rem', '2.3rem']
        },
        '& a': {
          fontFamily: 'primary.bold',
          linHeight: 1,
          fontSize: ['2.2rem', null, '2.6rem', null, '2.6rem', '2.8rem'],
          color: 'white',
          position: 'relative',
          ':hover > .__selected-indicator': {
            width: '100%'
          },
          // Animation value
          whiteSpace: 'nowrap'
        }
      }}>
      {text}
      <SelectedIndicator isPathMatch={isPathMatch} />
    </NavigationLink>
  );
};

const SelectedIndicator = ({ isPathMatch }) => {
  return (
    <Box
      className="__selected-indicator"
      sx={{
        position: 'absolute',
        top: 'calc(100% + 0.08em)',
        left: 0,
        borderTop: '4px solid',
        borderColor: 'red',
        width: isPathMatch ? '100%' : '0%',
        transition: 'width 0.5s ease'
      }}
    />
  );
};
