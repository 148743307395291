import { useStaticQuery, graphql } from 'gatsby';

export const useKisSolutionsData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisSolutions {
        nodes {
          entry {
            slug
            title
          }
        }
      }
    }
  `);

  return data;
};
