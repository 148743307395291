import React from 'react';

// External Components

import { Box } from '@thepuzzlers/pieces';

export const LineThree = ({ sx, ...props }) => {
  return (
    <Box
      className="line-three"
      as="svg"
      viewBox="0 0 260 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'red',
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M1 4.33075C7.22867 9.3207 17.1201 9.09462 25.6796 7.88347C34.2391 6.67231 43.3275 4.83137 51.3386 7.60894C52.6509 8.06111 53.9241 8.64245 55.2756 8.99773C57.2147 9.49834 59.2909 9.51448 61.328 9.40144C76.1945 8.59401 90.4147 1.21405 105.066 3.41028C107.436 3.76555 110.041 4.57298 110.922 6.43008C111.804 8.28718 109.277 10.8225 107.259 9.74057C108.67 7.36671 111.804 6.04251 114.879 5.25123C132.918 0.519657 153.132 7.78658 171.231 3.21649C175.912 2.03764 180.867 0.0836452 185.451 1.48858C189.447 2.71588 191.836 6.18785 195.695 7.70583C205.116 11.3877 215.106 1.63392 225.467 2.57054C231.598 3.1196 236.769 7.399 242.939 7.23752C248.462 7.07603 253.79 3.32953 259 4.87981"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
    </Box>
  );
};
