import { graphql, useStaticQuery } from 'gatsby';

export const useNavigationData = () => {
  const data = useStaticQuery(graphql`
    query {
      navigationDataJson {
        closeText
        openText
        legalLinks {
          text
          to
        }
        homeLink {
          text
          to
        }
        solutionLinkTitle
        noahGroupLinks {
          title
          links {
            text
            to
          }
        }
        noahExperienceLinks {
          title
          links {
            text
            to
          }
        }
        thePuzzlersLink {
          text
          to
        }
      }
    }
  `);

  return data.navigationDataJson;
};
