const linkDefault = {
  cursor: 'pointer',
  // Fix the fontWeight issue on safari
  fontWeight: 'normal'
};

const buttonDefault = {
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  width: 'min-content',
  // Fix the fontWeight issue on safari
  fontWeight: 'normal'
};

const buttonPrimaryDefault = {
  ...buttonDefault,
  bg: 'darkGray',
  fontFamily: 'primary.bold',
  lineHeight: 1.25,
  borderRadius: 0,
  color: 'white'
};

const hyperlinkDefault = {
  ...linkDefault
};

const links = {
  hyperlink: {
    // small
    ...hyperlinkDefault
  },
  footer: {
    ...linkDefault
  },
  legal: {
    ...linkDefault
  },
  contact: {
    ...linkDefault
  }
};

const buttons = {
  primary: {
    ...buttonPrimaryDefault,
    p: [
      '0.8rem 1.6rem 0.8rem 1.6rem',
      null,
      '0.8rem 1.6rem 0.8rem 1.6rem',
      null,
      '0.8rem 1.6rem 0.8rem 1.6rem',
      '0.8rem 1.6rem 0.8rem 1.6rem'
    ],
    fontSize: ['2rem', null, '2.4rem', null, '2.4rem', '2.4rem']
  },
  primaryMedium: {
    ...buttonPrimaryDefault,
    p: '1rem 2.4rem 1rem 2.4rem',
    fontSize: ['2.4rem', null, '2.4rem', null, '2.4rem', '2.4rem']
  },
  primaryBig: {
    ...buttonPrimaryDefault,
    p: [
      '1.2rem 2.4rem 1.2rem 2.4rem',
      null,
      '1.2rem 2.4rem 1.2rem 2.4rem',
      null,
      '1.2rem 2.4rem 1.2rem 2.4rem',
      '1.2rem 2.4rem 1.2rem 2.4rem'
    ],
    fontSize: ['2.4rem', null, '3.2rem', null, '3.2rem', '3.2rem']
  },
  clear: {
    ...buttonDefault,
    p: 0,
    bg: 'transparent'
  }
};

const cards = {};

export { links, buttons, cards };
