import React from 'react';

// External Components

import { Box } from '@thepuzzlers/pieces';

export const ExcitedMark = ({ sx, ...props }) => {
  return (
    <Box
      className="excited-mark"
      as="svg"
      viewBox="0 0 150 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'primary',
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M119.043 2.80847C123.482 2.55843 128.518 3.04227 134.151 4.26C139.784 5.47774 144.715 7.14834 148.943 9.27181C146.675 19.0627 140.186 40.3388 129.476 73.1001L113.316 122.675L104.433 120.755L110.193 68.9317C110.653 65.4027 110.911 62.8125 110.965 61.1612C112.35 48.4581 113.749 36.7407 115.162 26.009C116.606 15.1329 117.9 7.39941 119.043 2.80847ZM98.1813 170.658C93.5591 169.659 90.1003 167.475 87.8047 164.106C85.6535 160.768 85.0463 156.933 85.983 152.599C86.9197 148.266 89.0413 145.096 92.3477 143.089C95.8298 140.97 99.882 140.409 104.504 141.408C109.126 142.407 112.513 144.576 114.664 147.914C116.991 151.138 117.686 154.917 116.749 159.25C115.813 163.583 113.603 166.81 110.121 168.93C106.783 171.081 102.803 171.657 98.1813 170.658Z"
        fill="currentColor"
      />
      <path
        d="M88.0539 46.4859C88.0539 56.8309 85.6346 66.5085 80.7958 75.5186C75.957 84.5288 69.5331 93.1218 61.5241 101.298C53.5151 109.307 43.337 118.233 30.9897 128.078L54.0156 141.343L50.0111 148.351L13.7203 129.579C14.2209 128.078 15.4723 126.159 17.4745 123.823C19.4768 121.487 21.8127 118.901 24.4824 116.064C33.8262 105.552 41.585 94.1229 47.7586 81.7757C54.0991 69.2616 57.2693 57.1646 57.2693 45.4848C57.2693 33.3044 54.5162 24.044 49.01 17.7035C46.3403 17.0361 43.0032 16.7024 38.9987 16.7024C35.1611 16.7024 31.6571 17.0361 28.4869 17.7035C20.9785 27.548 14.5546 44.8174 9.21522 69.5119H0.205078L2.95818 15.2007C15.4723 9.86138 27.8195 7.19171 39.9999 7.19171C54.3494 7.19171 65.9457 10.6957 74.789 17.7035C83.6323 24.7114 88.0539 34.3056 88.0539 46.4859ZM35.7451 166.121C41.2513 166.121 45.5895 167.706 48.7597 170.876C51.93 173.879 53.5151 177.884 53.5151 182.889C53.5151 187.895 51.8465 191.983 48.5094 195.153C45.3392 198.323 41.0844 199.909 35.7451 199.909C30.4057 199.909 26.1509 198.323 22.9807 195.153C19.8105 191.983 18.2254 187.895 18.2254 182.889C18.2254 177.884 19.8105 173.879 22.9807 170.876C26.1509 167.706 30.4057 166.121 35.7451 166.121Z"
        fill="currentColor"
      />
    </Box>
  );
};
