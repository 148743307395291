import React from 'react';

// External Components

import { Box } from '@thepuzzlers/pieces';

export const CircleSwirl = ({ sx, ...props }) => {
  return (
    <Box
      className="circle-swirl"
      as="svg"
      viewBox="0 0 110 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'red',
        ...sx
      }}
      {...props}>
      <path
        d="M78.0415 10.1999C59.3287 23.4826 62.3104 64.6486 91.3563 60.4407C108.527 57.9565 114.336 41.1758 103.54 38.8438C73.0548 32.3038 48.5843 72.1517 62.6703 93.0896C66.0119 98.0579 74.6999 104.547 83.5936 99.7816C91.8704 95.2696 89.1972 85.7892 86.164 80.8209C81.0746 72.608 67.04 68.4508 53.2625 69.1606C33.8814 70.1745 6.53206 81.7335 23.4455 99.6295C44.9343 122.291 53.2111 66.9299 35.1667 50.5041C22.5715 38.9959 -5.24052 43.153 2.26515 60.2886C8.43418 74.3824 36.4519 59.4268 44.4202 43.0516C52.3886 26.6765 49.7667 -9.47052 34.2413 3.9135C4.27007 29.6169 77.2703 45.992 90.2767 24.2937"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
    </Box>
  );
};
