import React from 'react';

// Helper
import { convertApiHtmlText } from 'graphqlHooks/helper';

// External Components
import { Paragraph } from '@thepuzzlers/pieces';

export const BigDescriptionBlock = ({ description, sx }) => {
  return (
    <Paragraph
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(description.html) }}
      sx={{
        gridColumn: [
          '1/13',
          null,
          '3/ span 20',
          null,
          '4/ span 18',
          '5/ span 16'
        ],
        fontFamily: 'primary.normal',
        '& > span': {
          fontFamily: 'primary.bold'
        },
        lineHeight: 1.5,
        fontSize: ['2.4rem', null, '2.8rem', null, '3.2rem', '3.2rem'],
        ...sx
      }}
    />
  );
};
