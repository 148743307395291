import { useKisSolutionsData } from 'graphqlHooks';
import { convertStringToSlug } from 'graphqlHooks/helper';

export const useSolutionLinks = () => {
  const solutionsData = useKisSolutionsData();
  return solutionsData.nodes.map(({ entry: { title, slug } }) => ({
    text: title,
    to: `/loesung/${convertStringToSlug(slug)}/`
  }));
};
