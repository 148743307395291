import React from 'react';

// External Components

import { Box } from '@thepuzzlers/pieces';

export const SwirlLine = ({ sx, ...props }) => {
  return (
    <Box
      className="swirl-line"
      as="svg"
      viewBox="0 0 170 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'red',
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M15.7851 54.9632C6.85005 53.27 1.13422 46.3672 1.00283 39.6923C0.871427 33.0174 5.33896 26.7007 11.0548 21.4585C22.6835 10.7787 40.0609 3.45258 58.6538 1.40128C63.844 0.815188 69.3956 0.684942 74.1259 2.31296C81.6813 4.9178 85.229 11.3322 86.0503 17.3233C88.3826 33.9291 73.2718 51.0234 51.394 56.4935C49.7844 56.8842 48.0105 57.2424 46.4666 56.7866C43.8058 56.0051 43.2802 53.498 43.4116 51.4141C44.5285 35.3292 63.1541 22.2725 83.8165 16.2488C89.2367 14.6859 95.9051 13.5789 100.57 16.1186C102.836 17.3559 104.216 19.2769 105.037 21.2306C107.895 27.8403 105.234 35.1013 100.406 41.0599C92.4231 50.8931 83.0281 53.1724 83.1924 49.3628C83.4552 42.6553 94.7226 32.529 107.961 25.6914C114.991 22.0771 124.714 19.0165 132.598 22.0771C135.883 23.347 138.183 25.7239 139.661 28.1985C145.475 37.9992 138.314 50.7629 124.681 54.8981C123.17 55.3539 121.429 55.7446 119.852 55.3865C116.108 54.5399 116.108 50.7303 117.192 47.9952C122.678 34.2547 137.328 22.5981 155.724 17.2908C158.155 16.607 160.882 15.9883 163.345 16.6721C166.269 17.4861 167.747 19.8305 168.404 22.0446C170.178 28.0683 167.846 34.4827 163.345 39.7249C158.845 44.9671 152.406 49.2 145.541 52.8793"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
    </Box>
  );
};
