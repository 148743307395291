module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-thepuzzlers-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/vercel/path0/src/intl","languages":["de"],"locales":{"default":"de","translations":[]},"translatedPaths":[{"default":"privacy-policy","de":"datenschutz"},{"default":"legal","de":"impressum"},{"default":"process","de":"prozess"},{"default":"values-and-mission","de":"werte-und-leitbild"},{"default":"management-and-investors","de":"fuehrungsebene-und-investoren"}],"secondPath":null,"redirect":false,"wrapProvider":true},
    },{
      plugin: require('../node_modules/gatsby-source-keepitsimple/gatsby-browser.js'),
      options: {"plugins":[],"prefix":"noah"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
