import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useCommonSectionData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisCommonSectionsPage {
        nodes {
          entry {
            suitability {
              button_text
              button_to
              caption
              description
              headline
            }
            solution_process {
              button_text
              button_to
              description {
                html
              }
              headline {
                html
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
