import React from 'react';

// Local Components
import { NavigationBar } from './components';

// Data

export const Navigation = () => {
  return (
    // Markup
    <NavigationBar />
  );
};
