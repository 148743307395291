import React from 'react';

// External Components
import { Paragraph, Box } from '@thepuzzlers/pieces';

// Helper function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const ColumnedParagraph = ({
  firstText,
  secondText,
  isSecondStyle,
  sx,
  ...props
}) => {
  return (
    <Paragraph
      sx={{
        fontFamily: 'body.normal',
        lineHeight: 1.5,
        fontSize: isSecondStyle
          ? ['1.6rem', null, '1.6rem', null, '1.6rem', '1.6rem']
          : ['1.6rem', null, '1.7rem', null, '1.7rem', '1.7rem'],
        mt: ['2rem', null, '2rem', null, '3rem', '3.4rem'],

        display: isSecondStyle
          ? [null, null, null, null, null, 'grid']
          : [null, null, null, null, 'grid', 'grid'],
        gridTemplateColumns: isSecondStyle
          ? [null, null, null, null, null, '1fr 1fr']
          : [null, null, null, null, '1fr 1fr', '1fr 1fr'],
        gap: isSecondStyle
          ? [null, null, null, null, null, '7rem']
          : [null, null, null, null, '5rem', '12.4rem'],

        '& > span > span': {
          fontFamily: 'body.boldItalic'
        },
        ...sx
      }}
      {...props}>
      <ParagraphItem data={firstText} />
      <ParagraphBreak isSecondStyle={isSecondStyle} />
      <ParagraphBreak isSecondStyle={isSecondStyle} />
      <ParagraphItem data={secondText} />
    </Paragraph>
  );
};

const ParagraphItem = ({ data }) => {
  return (
    <Box
      as="span"
      dangerouslySetInnerHTML={{
        __html: convertApiHtmlText(data.html)
      }}
    />
  );
};

const ParagraphBreak = ({ isSecondStyle }) => {
  return (
    <Box
      as="br"
      sx={{
        display: isSecondStyle
          ? ['inline', null, 'inline', null, 'inline', 'none']
          : ['inline', null, 'inline', null, 'none', 'none']
      }}
    />
  );
};
