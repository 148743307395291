import React from 'react';

// External Components
import { GridItem, Box } from '@thepuzzlers/pieces';

// Grid Spacer - Usage: Inside sections/GridWrappers
export const GridSpacer = ({
  bg,
  height,
  children,
  sx,
  className,
  ...props
}) => {
  return (
    <GridItem
      className={`grid-spacer ${className}`}
      sx={{ bg, height, ...sx }}
      {...props}>
      {children}
    </GridItem>
  );
};

// Regular Spacer - Usage: In between sections
export const Spacer = ({ bg, height, sx }) => {
  return <Box className="spacer" sx={{ bg, height, ...sx }} />;
};
