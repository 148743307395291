import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisLandingPageData = () => {
  const { data } = useStaticQuery(graphql`
    query MyQuery {
      data: allKisLandingPage {
        nodes {
          entry {
            header {
              headline
              caption
              focus_list {
                text
              }
              focus_list_title
              quick_info_title
              quick_infos {
                text
              }
              seo_image {
                file
              }
            }
            about {
              headline {
                html
              }
              caption {
                html
              }
              description {
                html
              }
              second_description {
                html
              }
            }
            solution {
              solutions {
                button_text
                button_to
                description {
                  html
                }
                image {
                  alt
                  image {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
                title {
                  html
                }
              }
              caption {
                html
              }
            }
            passion {
              button_text
              button_to
              caption
              headline
            }
            strategy {
              button_text
              button_to
              caption
              description {
                html
              }
              headline {
                html
              }
              illustration_image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
            suitability {
              caption
              headline
              list {
                description {
                  html
                }
                title
                first_paragraph {
                  html
                }
                second_paragraph {
                  html
                }
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
