import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisValuesAndMissionPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisValuesAndMissionPage {
        nodes {
          entry {
            header {
              headline
              image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
              title
            }
            about {
              description {
                html
              }
            }
            description_list {
              list {
                first_text {
                  html
                }
                second_text {
                  html
                }
                title
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
