import React from 'react';

// External Components

import { Box } from '@thepuzzlers/pieces';

export const SpringLineTwo = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      className="spring-line-two"
      viewBox="0 0 114 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'red',
        ...sx
      }}
      {...props}>
      <path
        d="M10.8567 36.6542C4.90003 35.5356 1.08948 30.9748 1.00188 26.5646C0.914285 22.1544 3.89264 17.9808 7.70319 14.5172C15.4557 7.46091 27.0406 2.62046 39.4358 1.26513C42.896 0.877892 46.597 0.791837 49.7506 1.86749C54.7875 3.58854 57.1527 7.82663 57.7002 11.7851C59.2551 22.7568 49.1812 34.0511 34.596 37.6654C33.5229 37.9235 32.3403 38.1602 31.3111 37.859C29.5372 37.3427 29.1868 35.6862 29.2744 34.3093C30.019 23.6818 42.4361 15.055 56.211 11.0751C59.8245 10.0425 64.2701 9.31104 67.3799 10.9891C68.8909 11.8066 69.8107 13.0758 70.3582 14.3666C72.2635 18.7338 70.4896 23.5312 67.2703 27.4681C61.9487 33.9651 55.6854 35.471 55.7949 32.954C55.9701 28.5223 63.4817 21.8317 72.3073 17.3139C76.9938 14.926 83.4761 12.9037 88.732 14.926C90.922 15.765 92.455 17.3354 93.4405 18.9704C97.3167 25.4459 92.5426 33.879 83.4542 36.6112C82.4468 36.9124 81.2862 37.1706 80.235 36.9339C77.7384 36.3746 77.7384 33.8575 78.4611 32.0504C82.1183 22.9719 91.8856 15.2702 104.149 11.7635C105.77 11.3118 107.588 10.903 109.23 11.3548C111.179 11.8926 112.165 13.4416 112.603 14.9045C113.785 18.8844 112.23 23.1225 109.23 26.5861C106.23 30.0497 101.938 32.8464 97.3605 35.2774"
        stroke="#E6592C"
        stroke-miterlimit="10"
      />
    </Box>
  );
};
