import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisProcessPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisProcessPage {
        nodes {
          entry {
            steps {
              step_list {
                data {
                  description {
                    html
                  }
                  headline {
                    html
                  }
                  list {
                    text
                  }
                }
              }
            }
            header {
              headline
              title
              image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
            description {
              description {
                html
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
