export const fontFamilies = {
  body: {
    normal: 'HalvarMittel-Lt, sans-serif',
    boldItalic: 'HalvarMittel-MdSlanted, sans-serif'
  },
  primary: {
    normal: 'bridge-head-normal-light, sans-serif',
    bold: 'bridge-head-normal-bold, sans-serif'
  }
};
