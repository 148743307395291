import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const PassionText = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 920 193"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'text',
        ...sx
      }}
      {...props}>
      <path
        d="M70.6738 126.75C66.1738 126.75 62.8405 126.667 60.6738 126.5V179.5L78.9238 180.25V190H0.423828V180L14.4238 179.5V13L0.423828 12.5V2.5H70.1738C89.5072 2.5 105.507 6.91667 118.174 15.75C130.841 24.5833 137.174 38.8333 137.174 58.5C137.174 73.3333 134.007 85.9167 127.674 96.25C121.34 106.583 113.091 114.25 102.924 119.25C92.7572 124.25 82.0072 126.75 70.6738 126.75ZM68.6738 13.25C65.0072 13.25 62.3405 13.3333 60.6738 13.5V115.75C64.0072 116.083 66.5905 116.25 68.4238 116.25C72.9238 116.25 76.7572 115.833 79.9238 115C83.0905 110.5 85.2572 104.5 86.4238 97C87.7572 89.3333 88.4238 77.8333 88.4238 62.5C88.4238 48.1667 87.9238 37.5833 86.9238 30.75C85.9238 23.9167 83.9238 18.5 80.9238 14.5C77.5905 13.6667 73.5072 13.25 68.6738 13.25Z"
        fill="currentColor"
      />
      <path
        d="M274.98 180V190H198.98V180L219.23 179.5L208.48 144.25H158.23L155.98 151.25C156.147 157.083 157.23 162.417 159.23 167.25C161.397 172.083 164.147 176.25 167.48 179.75L177.98 180V190H125.23V180L132.98 179.5C136.48 173.833 139.897 165.667 143.23 155L192.23 0H208.73L265.98 179.5L274.98 180ZM205.23 134L196.23 104.75C191.73 90.4167 187.647 76.4167 183.98 62.75L161.48 134H205.23Z"
        fill="currentColor"
      />
      <path
        d="M365.447 85.75C375.947 94.25 383.364 102.583 387.697 110.75C392.197 118.75 394.447 127.833 394.447 138C394.447 147.667 392.197 156.667 387.697 165C383.364 173.167 376.364 179.833 366.697 185C357.031 190 344.864 192.5 330.197 192.5C314.031 192.5 299.281 189.333 285.947 183L282.447 124H292.447C295.781 136.667 300.031 147.75 305.197 157.25C310.531 166.75 316.114 174.667 321.947 181C325.281 181.667 328.947 182 332.947 182C337.447 182 341.697 181.5 345.697 180.5C351.197 174.167 353.947 166.583 353.947 157.75C353.947 150.583 351.947 144 347.947 138C344.114 131.833 337.947 125.333 329.447 118.5L309.197 102.5C300.364 95.5 293.614 88.0833 288.947 80.25C284.447 72.25 282.197 63.1667 282.197 53C282.197 43.8333 284.364 35.25 288.697 27.25C293.031 19.0833 299.531 12.5 308.197 7.50001C317.031 2.5 327.614 0 339.947 0C348.614 0 357.281 1 365.947 3.00001C374.614 5 381.364 7.33333 386.197 10L388.447 62H378.197C375.531 52 371.614 42.5833 366.447 33.75C361.281 24.75 355.031 17.4167 347.697 11.75C345.031 11.0833 342.031 10.75 338.697 10.75C335.031 10.75 331.114 11.1667 326.947 12C324.947 14.3333 323.364 17.25 322.197 20.75C321.031 24.25 320.364 27.75 320.197 31.25C320.197 38.0833 322.197 44.5 326.197 50.5C330.364 56.3333 336.947 62.8333 345.947 70L365.447 85.75Z"
        fill="currentColor"
      />
      <path
        d="M492.4 85.75C502.9 94.25 510.317 102.583 514.65 110.75C519.15 118.75 521.4 127.833 521.4 138C521.4 147.667 519.15 156.667 514.65 165C510.317 173.167 503.317 179.833 493.65 185C483.984 190 471.817 192.5 457.15 192.5C440.984 192.5 426.234 189.333 412.9 183L409.4 124H419.4C422.734 136.667 426.984 147.75 432.15 157.25C437.484 166.75 443.067 174.667 448.9 181C452.234 181.667 455.9 182 459.9 182C464.4 182 468.65 181.5 472.65 180.5C478.15 174.167 480.9 166.583 480.9 157.75C480.9 150.583 478.9 144 474.9 138C471.067 131.833 464.9 125.333 456.4 118.5L436.15 102.5C427.317 95.5 420.567 88.0833 415.9 80.25C411.4 72.25 409.15 63.1667 409.15 53C409.15 43.8333 411.317 35.25 415.65 27.25C419.984 19.0833 426.484 12.5 435.15 7.50001C443.984 2.5 454.567 0 466.9 0C475.567 0 484.234 1 492.9 3.00001C501.567 5 508.317 7.33333 513.15 10L515.4 62H505.15C502.484 52 498.567 42.5833 493.4 33.75C488.234 24.75 481.984 17.4167 474.65 11.75C471.984 11.0833 468.984 10.75 465.65 10.75C461.984 10.75 458.067 11.1667 453.9 12C451.9 14.3333 450.317 17.25 449.15 20.75C447.984 24.25 447.317 27.75 447.15 31.25C447.15 38.0833 449.15 44.5 453.15 50.5C457.317 56.3333 463.9 62.8333 472.9 70L492.4 85.75Z"
        fill="currentColor"
      />
      <path
        d="M548.115 179.5V13L534.115 12.5V2.5H608.365V12.5L594.365 13V179.5L608.365 180V190H534.115V180L548.115 179.5Z"
        fill="currentColor"
      />
      <path
        d="M692.559 192.5C670.559 192.5 652.975 184.75 639.809 169.25C626.642 153.583 620.059 129.333 620.059 96.5C620.059 75.5 623.142 57.75 629.309 43.25C635.642 28.75 644.225 17.9167 655.059 10.75C666.059 3.58334 678.559 0 692.559 0C714.559 0 732.142 7.5 745.309 22.5C758.475 37.5 765.059 60.5 765.059 91.5C765.059 113.667 761.892 132.333 755.559 147.5C749.225 162.667 740.559 174 729.559 181.5C718.725 188.833 706.392 192.5 692.559 192.5ZM680.059 180.5C683.559 181.333 687.725 181.75 692.559 181.75C697.392 181.75 701.475 181.333 704.809 180.5C707.475 176 709.392 170.917 710.559 165.25C711.725 159.583 712.559 151.5 713.059 141C713.559 130.333 713.809 114.667 713.809 94C713.809 75 713.559 60.5833 713.059 50.75C712.725 40.75 711.975 32.9167 710.809 27.25C709.642 21.5833 707.809 16.5833 705.309 12.25C701.642 11.25 697.392 10.75 692.559 10.75C687.559 10.75 683.392 11.1667 680.059 12C677.559 16.5 675.725 21.5833 674.559 27.25C673.392 32.75 672.559 40.5 672.059 50.5C671.725 60.5 671.559 75 671.559 94C671.559 114.5 671.725 130.083 672.059 140.75C672.559 151.417 673.392 159.583 674.559 165.25C675.725 170.917 677.559 176 680.059 180.5Z"
        fill="currentColor"
      />
      <path
        d="M919.553 2.5V12.75L911.553 13.25C909.719 16.5833 908.219 20.75 907.053 25.75C905.886 30.75 905.303 36.0833 905.303 41.75V192.5H888.803L802.553 51.5V153.25C804.219 159.083 806.469 164.333 809.303 169C812.303 173.667 815.719 177.167 819.553 179.5L830.553 180V190H776.553V179.75L784.553 179.25C786.386 175.917 787.886 171.75 789.053 166.75C790.219 161.75 790.803 156.417 790.803 150.75V32L775.303 8.25V2.5H823.803L893.303 115.25V39C889.636 26.5 883.969 17.8333 876.303 13L865.303 12.5V2.5H919.553Z"
        fill="currentColor"
      />
    </Box>
  );
};
