import { useStaticQuery, graphql } from 'gatsby';
import { useKisSectionData } from './helper';

export const useKisCaseStudyPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allKisCaseStudyPage {
        nodes {
          entry {
            header {
              headline {
                html
              }
              title
              image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
            about {
              description {
                html
              }
            }
            question {
              headline
            }
            passion {
              author {
                html
              }
              description {
                html
              }
              headline {
                html
              }
            }
            strategy {
              headline {
                html
              }
              image {
                alt
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
              list {
                text
              }
            }
          }
        }
      }
    }
  `);

  return useKisSectionData(data);
};
