import React from 'react';

// External Components

import { Box } from '@thepuzzlers/pieces';

export const LineTwo = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      viewBox="0 0 219 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="line-two"
      sx={{
        color: 'red',
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M1 9.7926C7.68144 5.62386 15.6232 2.79357 23.9275 1.60848C26.7762 1.20415 29.7457 0.995021 32.5253 1.63637C35.305 2.27771 37.8774 3.90896 38.637 6.16761C39.3967 8.42625 37.8429 11.1868 35.0287 11.7306C32.2146 12.2743 29.0551 9.83442 30.091 7.64549C30.609 6.55799 31.8865 5.83299 33.1296 5.24742C40.329 1.88733 49.1858 0.939251 57.2311 2.64021C60.7704 3.39309 64.1197 4.62001 67.5554 5.60992C74.375 7.57578 81.6089 8.57962 88.8601 8.57962C90.9836 8.57962 93.2453 8.46808 94.989 7.47818C96.7328 6.48828 97.6305 4.35511 96.3184 3.00271C94.7646 1.24598 91.6569 0.520982 89.1708 1.32963C86.6847 2.13829 85.0963 4.42482 85.4934 6.55799C86.1668 10.1412 91.3807 11.9118 95.8695 11.9955C107.264 12.1907 117.191 6.01424 128.172 3.60223C131.849 2.79357 135.665 2.41713 139.48 2.45896C141.638 2.48685 143.883 2.68204 145.695 3.61617C147.508 4.5503 148.769 6.39068 148.112 8.04981C147.456 9.70894 144.435 10.5455 142.985 9.27673C141.932 6.34885 146.472 4.27145 150.115 3.43492C155.778 2.12434 162.062 0.841656 167.345 2.91906C169.452 3.75559 171.213 5.05222 173.267 5.97241C176.513 7.42241 180.328 7.82674 184.04 7.86856C186.613 7.89645 189.341 7.74308 191.464 6.58587C193.588 5.42866 194.831 2.94694 193.467 1.17627C190.998 2.06858 191.119 5.20559 193.07 6.7253C195.021 8.245 197.921 8.57962 200.614 8.83058C206.484 9.38827 212.734 9.89019 218 7.72914"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
    </Box>
  );
};
