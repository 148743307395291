// No nesting! - colors declarations can only be one level deep
// No variables - you can't reference the colors by var name (e.g. 'primary') within this file

const base = {
  primary: '#000000', // dark blue
  darkGray: '#222222',
  secondary: '#FF9E1F', // yellow
  yellow: '#FFF0B5',
  background: '#F8F5EC',
  red: '#E6592C',
  beige: '#EBDFCD',
  white: '#FFFFFF',
  gray: '#E3E3E3',
  lightGreen: '#D0E7DC',
  orange: '#E88021',
  orange300: '#F0B881'
};

const textColors = {
  text: base.primary,
  heading: base.primary,
  background: base.background
};

export const colors = {
  // defaults
  ...base,
  ...textColors
};
