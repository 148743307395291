export const floatingLaptop = {
  initial: {
    y: -3
  },
  animate: {
    y: 3,
    transition: {
      repeatType: 'reverse',
      repeat: Infinity,
      duration: 0.8
    }
  }
};
