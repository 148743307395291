export const navMenuContainer = {
  open: ({ contentWidth, contentHeight, height }) => ({
    width: contentWidth,
    height: contentHeight + height,
    transition: {
      type: 'spring',
      duration: 0.05,
      bounce: 0.1,
      mass: 0.4
    }
  }),
  close: ({ width, height }) => ({
    height,
    width,
    transition: {
      type: 'spring',
      duration: 0.05,
      bounce: 0.1,
      mass: 0.35
    }
  })
};
