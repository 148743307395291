import React from 'react';

// External Components

import { Box } from '@thepuzzlers/pieces';

export const ScratchLine = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      className="scratch-line"
      viewBox="0 0 124 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        color: 'red',
        width: '100%',
        ...sx
      }}
      {...props}>
      <path
        d="M2.03748 22.2229C0.677319 24.3544 0.381632 28.7975 2.83584 29.2178C8.95657 30.2686 13.6284 19.2508 19.5718 21.022C23.534 22.1929 21.5529 28.3172 25.4264 28.9476C30.9262 29.8183 34.504 25.7654 37.0469 22.7332C42.3397 16.3687 46.0654 7.06209 52.0383 10.6646C59.7261 15.318 51.8313 27.7468 47.8986 36.6631C44.2321 44.979 36.7216 59.4193 42.1032 62.6616C58.2773 72.4185 73.0912 -8.78916 88.0826 2.1386C89.6793 3.30944 89.5315 5.68111 88.9105 7.57246C84.5639 20.6317 68.8333 42.5173 76.9352 45.2492C81.1931 46.6902 87.0772 37.4137 89.2653 32.6103C93.6415 22.9734 98.7273 7.66252 106.001 14.6275C111.146 19.551 101.536 36.0627 108.13 38.6445C119.219 42.9376 122.264 12.556 134.032 14.1171C138.113 14.6575 132.377 23.874 136.073 25.7954C136.96 26.2457 137.995 26.1557 139 26.0656"
        stroke="currentColor"
        stroke-miterlimit="10"
      />
    </Box>
  );
};
