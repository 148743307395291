import React from 'react';

// External Components
import { Section, Box, Heading, Paragraph } from '@thepuzzlers/pieces';
import { PrimaryButton } from 'components';
import { useCommonSectionData } from 'graphqlHooks';

export const Suitability = () => {
  const {
    suitability: { button_text, button_to, caption, description, headline }
  } = useCommonSectionData();

  return (
    <Section id="solution__suitability">
      <ContentContainer
        data={{
          caption: caption,
          headline: headline,
          description: description,
          buttonText: button_text,
          buttonTo: button_to
        }}
      />
    </Section>
  );
};

const ContentContainer = ({
  data: { caption, headline, description, buttonText, buttonTo }
}) => {
  return (
    <Box
      className="__content-container"
      sx={{
        gridColumn: [
          '1/13',
          null,
          '2/ span 22',
          null,
          '4/ span 18',
          '6/ span 14'
        ],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <Heading
        as="h2"
        sx={{
          fontFamily: 'body.normal',
          lineHeight: 1,
          letterSpacing: '0.05em',
          textTransform: 'uppercase',
          fontSize: ['1.4rem', null, '1.8rem', null, '1.8rem', '1.8rem'],
          textAlign: 'center'
        }}>
        {caption}
      </Heading>
      <Heading
        as="h3"
        sx={{
          fontFamily: 'primary.bold',
          lineHeight: 1.1,
          fontSize: ['4rem', null, '7.2rem', null, '6.4rem', '7.2rem'],
          textAlign: 'center',
          mt: ['1.8rem', null, '2rem', null, '2.4rem', '2.4rem'],
          width: [null, null, null, null, '71.3rem', '71.3rem']
        }}>
        {headline}
      </Heading>
      <Paragraph
        sx={{
          fontFamily: 'body.normal',
          lineHeight: 1.5,
          fontSize: ['1.5rem', null, '1.6rem', null, '1.6rem', '1.6rem'],
          textAlign: 'center',
          mt: ['3rem', null, '3rem', null, '3rem', '2.9rem'],
          width: [null, null, '52.4rem', null, '52.8rem', '52.4rem']
        }}>
        {description}
      </Paragraph>

      <PrimaryButton
        isSecondAnimationVariant
        text={buttonText}
        to={buttonTo}
        textSx={{
          p: '1rem 2.4rem 1rem 2.4rem',
          fontSize: ['2.8rem', null, '2.8rem', null, '2.8rem', '2.8rem']
        }}
        sx={{
          mt: '5rem'
        }}
      />
    </Box>
  );
};
