exports.components = {
  "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-legal-js": () => import("./../../../node_modules/gatsby-theme-thepuzzlers-legal-pages/src/pages/legal.js" /* webpackChunkName: "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-legal-js" */),
  "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-privacy-policy-js": () => import("./../../../node_modules/gatsby-theme-thepuzzlers-legal-pages/src/pages/privacy-policy.js" /* webpackChunkName: "component---node-modules-gatsby-theme-thepuzzlers-legal-pages-src-pages-privacy-policy-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-management-and-investors-js": () => import("./../../../src/pages/management-and-investors.js" /* webpackChunkName: "component---src-pages-management-and-investors-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-values-and-mission-js": () => import("./../../../src/pages/values-and-mission.js" /* webpackChunkName: "component---src-pages-values-and-mission-js" */),
  "component---src-templates-solution-solution-js": () => import("./../../../src/templates/solution/Solution.js" /* webpackChunkName: "component---src-templates-solution-solution-js" */)
}

